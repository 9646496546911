export default {
    data(){
        return {
            master:{
                belong_to:{
                    '0':'該当なし',
                    '1':'一般社団法人リビングアメニティ協会',
                    '2':'一般社団法人日本建材・住宅設備産業協会',
                },
                organizationsStatus:{
                    'not_approved':'非承認',
                    'approved':'承認済',
                    'deleted':'削除済',
                },
                devices_status:{
                    '1':{
                        japanese:'公開申請前',
                        english:'before_apply',
                    },
                    '2':{
                        japanese:'公開申請済',
                        english:'applied',
                    },
                    '3':{
                        japanese:'公開承認済',
                        english:'approved',
                    },
                    '4':{
                        japanese:'一般公開中',
                        english:'published',
                    },
                    '5':{
                        japanese:'取下公開中',
                        english:'canceled',
                    },
                    '6':{
                        japanese:'削除済',
                        english:'deleted',
                    },
                },
                informationShowType:{
                    0:'住宅・非住宅共通',
                    1:'住宅版のみ',
                    2:'非住宅版のみ',
                },
            },
        }
    }
}